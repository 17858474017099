<template>
  <b-badge style="font-size: 100%;" variant="warning" :href="'/machine/' + serial + '/review'">
    <font-awesome-icon icon="magnifying-glass"/>
    <span v-if="reviewCount === 1"> 1 open review</span>
    <span v-else>{{ reviewCount }} open reviews</span>
  </b-badge>
</template>

<script>

export default {
  name: 'OpenReviews',
  props: {
    reviewCount: {
      type: Number
    },
    serial: {
      type: String
    }
  },
  components: {},
}
</script>

<style lang="scss">
</style>

<template>
  <div class="card">
    <div class="card-header d-flex align-items-center justify-content-between">
      <span>Static Data</span>
    </div>
    <ul class="list-group list-group-flush">
      <li class="list-group-item">
        <div class="float-left">
          <font-awesome-icon icon="barcode"/>
          S/N
        </div>
        <div class="float-right">
          <b-form-input v-if="editable" class="text-right" v-model="machineFile.static.machineSerialNumber" @input="emitChange"></b-form-input>
          <span v-else>{{ machineFile.static.machineSerialNumber }}</span>
        </div>
      </li>
      <li class="list-group-item">
        <div class="float-left">
          <font-awesome-icon icon="tags"/>
          Machine Type
        </div>
        <div class="float-right">
          <b-form-input v-if="editable" class="text-right" v-model="machineFile.static.machineType" @input="emitChange"></b-form-input>
          <span v-else>{{ machineFile.static.machineType }}</span>
        </div>
      </li>
      <li class="list-group-item">
        <div class="float-left">
          Dealer name
        </div>
        <div class="float-right">
          <b-form-input v-if="editable" class="text-right" v-model="machineFile.static.dealerName" @input="emitChange"></b-form-input>
          <span v-else>{{ machineFile.static.dealerName }}</span>
        </div>
      </li>
      <li class="list-group-item">
        <div class="float-left">
          Customer equipment code
        </div>
        <div class="float-right">
          <b-form-input v-if="editable" class="text-right" v-model="machineFile.static.customerEquipmentCode" @input="emitChange"></b-form-input>
          <span v-else>{{ machineFile.static.customerEquipmentCode }}</span>
        </div>
      </li>
      <li class="list-group-item">
        <div class="float-left">
          <font-awesome-icon icon="briefcase"/>
          Customer Name
        </div>
        <div class="float-right">
          <b-form-input v-if="editable" class="text-right" v-model="machineFile.static.customerName" @input="emitChange"></b-form-input>
          <span v-else>{{ machineFile.static.customerName }}</span>
        </div>
      </li>
      <li class="list-group-item">
        <div class="float-left">
          <font-awesome-icon icon="location"/>
          Site Name
        </div>
        <div class="float-right">
          <b-form-input v-if="editable" class="text-right" v-model="machineFile.static.siteName" @input="emitChange"></b-form-input>
          <span v-else>{{ machineFile.static.siteName }}</span>
        </div>
      </li>
      <li class="list-group-item">
        <div class="float-left">
          Application
        </div>
        <div class="float-right">
          <b-form-input v-if="editable" class="text-right" v-model="machineFile.static.application" @input="emitChange"></b-form-input>
          <span v-else>{{ machineFile.static.application }}</span>
        </div>
      </li>
      <li class="list-group-item">
        <div class="float-left">
          Decks Amount
        </div>
        <div class="float-right">
          <b-form-input v-if="editable" class="text-right" v-model="machineFile.static.decksAmount" @input="emitChange"></b-form-input>
          <span v-else>{{ machineFile.static.decksAmount }}</span>
        </div>
      </li>
      <li class="list-group-item">
        <div class="float-left">
          Drive Type
        </div>
        <div class="float-right">
          <b-form-input v-if="editable" class="text-right" v-model="machineFile.static.driveType" @input="emitChange"></b-form-input>
          <span v-else>{{ machineFile.static.driveType }}</span>
        </div>
      </li>
      <li class="list-group-item">
        <div class="float-left">
          Drive Position
        </div>
        <div class="float-right">
          <b-form-input v-if="editable" class="text-right" v-model="machineFile.static.drivePosition" @input="emitChange"></b-form-input>
          <span v-else>{{ machineFile.static.drivePosition }}</span>
        </div>
      </li>
      <li class="list-group-item">
        <div class="float-left">
          Shaft Lines
        </div>
        <div class="float-right">
          <b-form-input v-if="editable" class="text-right" v-model="machineFile.static.shaftLinesAmount" @input="emitChange"></b-form-input>
          <span v-else>{{ machineFile.static.shaftLinesAmount }}</span>
        </div>
      </li>
      <li class="list-group-item">
        <div class="float-left">
          Exciters Amount
        </div>
        <div class="float-right">
          <b-form-input v-if="editable" class="text-right" v-model="machineFile.static.excitersAmount" @input="emitChange"></b-form-input>
          <span v-else>{{ machineFile.static.excitersAmount }}</span>
        </div>
      </li>
      <li class="list-group-item">
        <div class="float-left">
          Mechanism Type
        </div>
        <div class="float-right">
          <b-form-input v-if="editable" class="text-right" v-model="machineFile.static.mechanismType" @input="emitChange"></b-form-input>
          <span v-else>{{ machineFile.static.mechanismType }}</span>
        </div>
      </li>
      <li class="list-group-item" v-if="machineFile.static.exciterTypes[0] != null">
        <div class="float-left" v-if="machineFile.static.shaftLinesAmount == 1">
          Exciter type
        </div>
        <div class="float-left" v-else>
          Exciter type - Feed side
        </div>
        <div class="float-right">
          <b-form-input v-if="editable" class="text-right" v-model="machineFile.static.exciterTypes[0]" @input="emitChange"></b-form-input>
          <span v-else>{{ machineFile.static.exciterTypes[0] }}</span>
        </div>
      </li>
      <li class="list-group-item" v-if="machineFile.static.exciterTypes[1] != null">
        <div class="float-left" v-if="machineFile.static.shaftLinesAmount == 2">
          Exciter type - Discharge side
        </div>
        <div class="float-left" v-else>
          Exciter type - Middle
        </div>
        <div class="float-right">
          <b-form-input v-if="editable" class="text-right" v-model="machineFile.static.exciterTypes[1]" @input="emitChange"></b-form-input>
          <span v-else>{{ machineFile.static.exciterTypes[1] }}</span>
        </div>
      </li>
      <li class="list-group-item" v-if="machineFile.static.exciterTypes[2] != null">
        <div class="float-left">
          Exciter type - Discharge side
        </div>
        <div class="float-right">
          <b-form-input v-if="editable" class="text-right" v-model="machineFile.static.exciterTypes[2]" @input="emitChange"></b-form-input>
          <span v-else>{{ machineFile.static.exciterTypes[2] }}</span>
        </div>
      </li>
      <li class="list-group-item">
        <div class="float-left">
          Lubrication
        </div>
        <div class="float-right">
          <b-form-input v-if="editable" class="text-right" v-model="machineFile.static.lubrication" @input="emitChange"></b-form-input>
          <span v-else>{{ machineFile.static.lubrication }}</span>
        </div>
      </li>
      <li class="list-group-item">
        <div class="float-left">
          Motion Type
        </div>
        <div class="float-right">
          <b-form-input v-if="editable" class="text-right" v-model="machineFile.static.motionType" @input="emitChange"></b-form-input>
          <span v-else>{{ machineFile.static.motionType }}</span>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'StaticData',
  props: {
    data: {
      type: Object
    },
    editable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      machineFile: { ...this.data }
    };
  },
  methods: {
    emitChange() {
      this.$emit('onChange', this.machineFile);
    }
  },
  components: {},
}
</script>

<style lang="scss">
</style>

import { MachineFile } from '@/screencheck_portal_api/src'

export function defaultMachineFile (serial) {
  const machineFile = new MachineFile()
  machineFile.static = Object.assign({
    machineSerialNumber: serial,
    machineType: null,
    dealerName: null,
    customerEquipmentCode: null,
    customerName: null,
    siteName: null,
    application: null,
    decksAmount: null,
    driveType: null,
    drivePosition: null,
    shaftLinesAmount: null,
    excitersAmount: null,
    mechanismType: null,
    exciterTypes: [],
    lubrication: null,
    motionType: null
  }, machineFile.static);

  machineFile.fixed = Object.assign({
    weight: null,
    weightMethod: null,
    process: {
      materialType: null,
      dryWet: null,
      topFeedSize: null,
      feedTonnage: null,
      dropHeightFeedMaterial: null,
      feedSizeMin: null,
      feedSizeMax: null,
      constant: null,
      surging: null
    },
    counterweights: [],
    media: {
      topDeck: {
        type: null,
        competitorName: null,
        typeSpecificity: null,
        material: null,
        openingSize: null,
        openingShape: null
      },
      bottomDeck: {
        type: null,
        competitorName: null,
        typeSpecificity: null,
        material: null,
        openingSize: null,
        openingShape: null
      },
      upperMidDeck: {
        type: null,
        competitorName: null,
        typeSpecificity: null,
        material: null,
        openingSize: null,
        openingShape: null
      },
      lowerMidDeck: {
        type: null,
        competitorName: null,
        typeSpecificity: null,
        material: null,
        openingSize: null,
        openingShape: null
      }
    },
    isolationFramePresent: null,
    screenSuspensionType: null,
    screenSuspensionFeedDischarge: {
      feedLeftInner: null,
      feedLeftOuter: null,
      feedRightInner: null,
      feedRightOuter: null,
      dischargeLeftInner: null,
      dischargeLeftOuter: null,
      dischargeRightInner: null,
      dischargeRightOuter: null
    },
    screenSidewaysSuspensionType: null,
    isolationFrameMountedOnSuspensions: null,
    isolationFrameCorrectlySpecified: null,
    isolationFrameSuspensionType: null,
    isolationFrameFeedDischarge: {
      feedLeftInner: null,
      feedLeftOuter: null,
      feedRightInner: null,
      feedRightOuter: null,
      dischargeLeftInner: null,
      dischargeLeftOuter: null,
      dischargeRightInner: null,
      dischargeRightOuter: null
    },
    lubricationSpec: null,
    greasingPowerUnit: null,
    motor: {
      motorFrequency: null,
      expectedSpeed: null,
      rotationDirection: null,
      vsdvfdInstalled: null,
      electronicBrakeInstalled: null,
      motorSheaveDiameterAsSpecified: null,
      drivenSheaveDiameterAsSpecified: null,
      motorA: {
        type: null,
        manufacturer: null,
        serial: null
      },
      motorB: {
        type: null,
        manufacturer: null,
        serial: null
      },
      exciterSerials: [],
      exciterLocations: []
    },
    gaDrawingNumberRevision: null,
    instructionManualNumberRevision: null,
    sparePartBookNumberRevision: null
  }, machineFile.fixed);
  return machineFile;
}
<template>
  <div class="col-md-12">
    <div class="card">
      <div class="card-header">
        <b-row>
          <div class="col-md-4 font-weight-bold">{{ dataName }}</div>
          <div class="col-md-4">Current</div>
          <div class="col-md-4">Changes</div>
        </b-row>
      </div>
      <ul class="list-group list-group-flush" v-for="key in dataKeys" :key="key">
        <li class="list-group-item align-items-center">
          <b-row>
            <div class="col-md-4">{{ convertCamelCaseToWords(key) }}</div>
            <div class="col-md-4">
              <font-awesome-icon v-if="!isDifferent(key, masterData, mergedData)" class="icon mr-1" icon="check"/>
              <span :class="{ 'rejected': isDifferent(key, masterData, mergedData) }">{{ masterData[key] }}</span>
            </div>
            <div class="col-md-4" v-if="isDifferent(key, masterData, machineData)">
              <font-awesome-icon v-if="!isDifferent(key, machineData, mergedData)" class="icon mr-1" icon="check"/>
              <span :class="{ 'accepted': !isDifferent(key, machineData, mergedData), 'open': isDifferent(key, masterData, machineData) }">{{ machineData[key] }}</span>
              <b-btn size="sm" class="float-right" variant="outline-dark" v-if="isDifferent(key, masterData, mergedData)" @click="acceptDifference(key, masterData, mergedData)"><font-awesome-icon class="icon mr-1" icon="undo"/>revert</b-btn>
              <b-btn size="sm" class="float-right" variant="outline-dark" v-if="isDifferent(key, machineData, mergedData)" @click="acceptDifference(key, machineData, mergedData)">accept</b-btn>
            </div>
          </b-row>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
export default {
  name: 'MachineFileMergerData',
  props: {
    dataName: String,
    dataKeys: {},
    masterData: {
      type: Object,
      required: true,
    },
    machineData: {
      type: Object,
      required: true,
    },
    mergedData: {
      type: Object,
      required: true,
    },
  },
  methods: {
    isDifferent(key, source, destination) {
      if (!destination || !source) {
        return true;
      }

      if (Array.isArray(destination[key]) && Array.isArray(source[key])) {
        if (destination[key].length !== source[key].length) {
          return true;
        }
        for (let i = 0; i < destination[key].length; i++) {
          if (destination[key][i] !== source[key][i]) {
            return true;
          }
        }
        return false;
      }

      return destination[key] !== source[key] && destination[key] !== null;
    },
    acceptDifference (key, source, destination) {
      if (source && key in source) {
        Vue.set(destination, key, source[key]);
      } else {
        Vue.delete(destination, key);
      }
      this.$emit('onMergedFileChanged', this.mergedData);
    },
    convertCamelCaseToWords (camelCaseString) {
      return camelCaseString
          .replace(/([A-Z])/g, ' $1')
          .replace(/^./, (match) => match.toUpperCase())
          .trim();
    }
  }
};
</script>

<style lang="scss">

.accepted {
  color: green;
}

.open {
  font-weight: bold;
}

.rejected {
  color: red;
  text-decoration: line-through;
}

</style>

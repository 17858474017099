<template>
  <div class="card">
    <div class="card-header d-flex align-items-center justify-content-between">
      <span>Application</span>
    </div>
    <ul class="list-group list-group-flush">
      <li class="list-group-item">
        <div class="float-left">
          Material type
        </div>
        <div class="float-right">
          <b-form-input v-if="editable" class="text-right" v-model="machineFile.fixed.process.materialType" @input="emitChange"></b-form-input>
          <span v-else>{{ machineFile.fixed.process.materialType }}</span>
        </div>
      </li>
      <li class="list-group-item">
        <div class="float-left">
          Dry/Wet screening
        </div>
        <div class="float-right">
          <b-form-input v-if="editable" class="text-right" v-model="machineFile.fixed.process.dryWet" @input="emitChange"></b-form-input>
          <span v-else>{{ machineFile.fixed.process.dryWet }}</span>
        </div>
      </li>
      <li class="list-group-item">
        <div class="float-left">
          Top feed size (mm)
        </div>
        <div class="float-right">
          <b-form-input v-if="editable" class="text-right" v-model="machineFile.fixed.process.topFeedSize" @input="emitChange"></b-form-input>
          <span v-else>{{ machineFile.fixed.process.topFeedSize }}</span>
        </div>
      </li>
      <li class="list-group-item">
        <div class="float-left">
          Top feed size (mm)
        </div>
        <div class="float-right">
          <b-form-input v-if="editable" class="text-right" v-model="machineFile.fixed.process.feedTonnage" @input="emitChange"></b-form-input>
          <span v-else>{{ machineFile.fixed.process.feedTonnage }}</span>
        </div>
      </li>
      <li class="list-group-item">
        <div class="float-left">
          Drop height (mm)
        </div>
        <div class="float-right">
          <b-form-input v-if="editable" class="text-right" v-model="machineFile.fixed.process.dropHeightFeedMaterial" @input="emitChange"></b-form-input>
          <span v-else>{{ machineFile.fixed.process.dropHeightFeedMaterial }}</span>
        </div>
      </li>
      <li class="list-group-item">
        <div class="float-left">
          Feed size max (mm)
        </div>
        <div class="float-right">
          <b-form-input v-if="editable" class="text-right" v-model="machineFile.fixed.process.feedSizeMin" @input="emitChange"></b-form-input>
          <span v-else>{{ machineFile.fixed.process.feedSizeMin }}</span>
        </div>
      </li>
      <li class="list-group-item">
        <div class="float-left">
          Feed size min (mm)
        </div>
        <div class="float-right">
          <b-form-input v-if="editable" class="text-right" v-model="machineFile.fixed.process.feedSizeMax" @input="emitChange"></b-form-input>
          <span v-else>{{ machineFile.fixed.process.feedSizeMax }}</span>
        </div>
      </li>
      <li class="list-group-item">
        <div class="float-left">
          Constant (t/h)
        </div>
        <div class="float-right">
          <b-form-input v-if="editable" class="text-right" v-model="machineFile.fixed.process.constant" @input="emitChange"></b-form-input>
          <span v-else>{{ machineFile.fixed.process.constant }}</span>
        </div>
      </li>
      <li class="list-group-item">
        <div class="float-left">
          Surging (t/h)
        </div>
        <div class="float-right">
          <b-form-input v-if="editable" class="text-right" v-model="machineFile.fixed.process.surging" @input="emitChange"></b-form-input>
          <span v-else>{{ machineFile.fixed.process.surging }}</span>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>

export default {
  name: 'ApplicationData',
  props: {
    data: {
      type: Object
    },
    editable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      machineFile: { ...this.data }
    };
  },
  methods: {
    emitChange() {
      this.$emit('onChange', this.machineFile);
    }
  },
  components: {},
}
</script>

<style lang="scss">
</style>
